import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { PaginatedList } from "@app/models/common/paginated-list.class";
import { SimpleUser, User } from "@app/models/authorisation/user.class";
import { environment } from "@environments/environment";
import { Observable } from "rxjs";
import { IdentityProvider } from "@app/models/authorisation/identityProvider.class";
import { UpdateRoleAllocation } from "@app/models/authorisation/roleAllocation.class";
import { UserInvite } from "@app/models/authorisation/user-invite.class";

@Injectable()
export class AuthorisationService {
  private uri: string = `${environment.routes.apiEndpoint}/authorisation`;

  constructor(private http: HttpClient) { }

  public getIdps(): Observable<{ items: IdentityProvider[] }> {
    return this.http.get<{ items: IdentityProvider[] }>(`${this.uri}/identityProviders`);
  }

  public getUsers(userIds: string[] = [],
    searchText: string | null = null,
    includeAllocations: boolean = true,
    sortByField: string = 'name',
    sortDescending: boolean = false,
    pageSize: number = 10,
    pageIndex: number = 0): Observable<PaginatedList<User>> {
    let filters = `PageSize=${pageSize}&PageIndex=${pageIndex + 1}&SortByField=${sortByField}&SortDescending=${sortDescending}&IncludeAllocations=${includeAllocations}`;
    if (searchText) filters += `&SearchText=${searchText}`;
    if (userIds.length > 0) filters += `&UserIds=${userIds.join('&UserIds=')}`;

    return this.http.get<PaginatedList<User>>(`${this.uri}/users?${filters}`);
  }

  getUsersSimple(userIds: string[] = [],
    sortByField: string = 'name',
    sortDescending: boolean = false,
    pageSize: number = 10,
    pageIndex: number = 0) {
    let filters = `PageSize=${pageSize}&PageIndex=${pageIndex + 1}&SortByField=${sortByField}&SortDescending=${sortDescending}`;
    if (userIds.length > 0) filters += `&UserIds=${userIds.join('&UserIds=')}`;
    return this.http.get<PaginatedList<SimpleUser>>(`${this.uri}/users/basic?${filters}`);
  }

  public getCurrentUser() {
    return this.http.get<User>(`${this.uri}/users/me`);
  }

  public inviteNewUser(userInvite: UserInvite) {
    return this.http.post(`${this.uri}/users/invitations`, userInvite);
  }

  public updateUserAllocations(userId: string, allocations: UpdateRoleAllocation[]) {
    const requestBody = { 'allocations': allocations };
    return this.http.put(`${this.uri}/users/${userId}/allocations`, requestBody);
  }

  public deleteUser(userId: string) {
    return this.http.delete(`${this.uri}/users/${userId}`);
  }

}
